import React from 'react';
import { Bar } from 'react-chartjs-2';
import { sliceInToGroups } from '../../helpers/helper';
import { Chart as ChartJS } from 'chart.js/auto';
import styled from 'styled-components';

export default function CashFlowBarChart({ chartData }) {

    // ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
    const [barData, setBarData] = React.useState({
        data: {
            labels: [],
            datasets: []
        },
        options: {}
    }
    );

    React.useEffect(() => {
        // Exit if no data
        if (!chartData || !chartData.inflow || !chartData.outflow) { return; }

        // Group chart data by category
        let days = [];
        let inflow = chartData.inflow;
        let outflow = chartData.outflow;

        // Sort chart data by date
        if (Array.isArray(inflow)) {
            inflow.sort((a, b) => { 
              return new Date(a.inflow_date) - new Date(b.inflow_date); 
            });
          } else {
            console.error("inflow is not an array:", inflow);
            // Handle the error appropriately, e.g., display a message to the user,
            // set a default value, or skip the sorting.
          }
          
          if (Array.isArray(outflow)) {
            outflow.sort((a, b) => { 
              return new Date(a.outflow_date) - new Date(b.outflow_date); 
            });
          } else {
            console.error("outflow is not an array:", outflow);
            // Handle the error appropriately
          }
        inflow = sliceInToGroups(inflow, 1).map((item) => {
            let total = item.reduce((acc, curr) => { return acc + parseFloat(curr.value); }, 0);
            item.length > 0 && days.push(`${item[0]?.inflow_date ?? ''}`);
            return {
                value: total,
                inflow_date: `${item[0]?.inflow_date}`
            };
        });
        outflow = sliceInToGroups(outflow, 1).map((item) => {
            let total = item.reduce((acc, curr) => { return acc + parseFloat(curr.value); }, 0);
            item.length > 0 && days.push(`${item[0]?.outflow_date ?? ''}`);
            return {
                value: total,
                outflow_date: `${item[0]?.outflow_date}`
            };
        });
        // }
        // Sort chart data for plotting
        let inflow_ = inflow?.map((item) => { return Math.abs(item.value.toFixed(2)); });
        let outflow_ = outflow?.map((item) => { return Math.abs(item.value.toFixed(2)); });

        // List chart data for plotting
        const labels = [...new Set(days)] ?? [];
        const data = {
            labels,
            datasets: [
                {
                    label: 'Inflow',
                    data: inflow_ ?? [0, 0, 0, 0, 0, 0, 0],
                    backgroundColor: '#2C6DEA',
                    borderColor: '#2C6DEA',
                    tension: 0.4,
                    barPercentage: 1,
                    categoryPercentage: 0.1,
                    borderRadius: {
                        topLeft: 0,
                        topRight: 0,
                        bottomLeft: Number.MAX_VALUE,
                        bottomRight: Number.MAX_VALUE,
                      },
                },
                {
                    label: 'Outflow',
                    data: outflow_ ?? [0, 0, 0, 0, 0, 0, 0],
                    backgroundColor: '#C0D6FF',
                    borderColor: '#C0D6FF',
                    tension: 0.4,
                    barPercentage: 1,
                    categoryPercentage: 0.1,
                    borderRadius: {
                        topLeft: Number.MAX_VALUE,
                        topRight: Number.MAX_VALUE,
                        bottomLeft: 0,
                        bottomRight: 0,
                      },
                },
            ],
        };

        const options = {
            interaction: {
                intersect: false,
                mode: 'nearest',
            },
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    enabled: true,
                    position: 'nearest',
                    backgroundColor: 'rgba(113, 116, 133, 1)',
                    titleColor: 'rgba(251, 251, 251, 1)',
                    titleFont: { family: 'Inter', style: 'normal', weight: '500', size: '12px', lineHeight: '16px' },
                    titleAlign: 'center',
                    titleSpacing: 2,
                    bodyColor: 'rgba(243, 246, 247, 1)',
                    bodyFont: { family: 'Inter', style: 'normal', weight: 'normal', size: '12px', lineHeight: '16px' },
                    bodyAlign: 'center',
                    bodySpacing: 2,
                    // padding: 9,
                    displayColors: false,
                    callbacks: {
                        title: function (tooltipItem) {
                            return '';
                        },
                        label: function (tooltipItem) {
                            return tooltipItem?.dataset?.label;
                        },
                        footer: function (tooltipItem) {
                            return `NGN ${tooltipItem[0]?.formattedValue}`;
                        }
                    }
                }
            },
            scales: {
                y: {
                    beginAtZero: true,
                    grid: {
                        display: false,
                        borderDash: [2, 2],
                        drawTicks: false,
                        drawBorder: false,
                    },
                    stacked: true,
                    ticks: {
                        display: true,
                        color: 'rgba(156, 163, 175, 1)',
                    }
                },
                x: {
                    grid: {
                        display: false,
                        drawBorder: false,
                        // stacked: true
                    },
                    stacked: true,
                    ticks: {
                        display: true,
                        font: {
                            family: 'Inter',
                            size: '14px',
                            style: 'normal',
                            weight: 'normal',
                            lineHeight: '20px',
                        },
                        color: 'rgba(156, 163, 175, 1)',
                    }
                },
            }
        };

        // Set calculated chart data to state
        setBarData({ data: data, options: options });

    }, [chartData]);

    return (

        <Div>
            <Bar data={barData.data} options={barData.options} />
        </Div>
    );
}

const Div = styled.div`
height: auto;
width: 100%;

@media (max-width: 650px) {
    height: auto;
    width: 100vw;
}
`;